import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

export default function BreakDown(props) {
  const { value, display, period } = props;

  const classes = useStyles();

  return (
    <Typography variant='subtitle1' component='div' className={classes.root}>{display(value)} <Typography variant='subtitle2' component='span'>{period}</Typography></Typography>
  )
}
