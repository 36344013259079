import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonGroup, Button } from '@material-ui/core';

const StyledButton = withStyles(theme => ({
  root: {
    color: theme.palette.common.black,
    background: 'transparent',
    width: 160,
    height: 72,
    border: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    borderRadius: 4,
    fontWeight: 700,
    fontSize: '1.25rem',
    fontFamily: 'inherit',
    lineHeight: '1.5rem',
    textTransform: 'none',
    padding: theme.spacing(2, 5),
    '&:hover': {
      color: theme.palette.common.black,
      background: theme.palette.primary.hover,
      borderColor: theme.palette.primary.hover,
      '& + button': {
        borderLeftColor: 'transparent'
      }
    },
    '&:active': {
      color: theme.palette.common.black,
      background: theme.palette.primary.active,
      borderColor: theme.palette.primary.active,
      '& + button': {
        borderLeftColor: 'transparent'
      }
    },
    '&:disabled': {
      color: theme.palette.common.black,
      background: theme.palette.primary.disabled,
      borderColor: theme.palette.primary.disabled
    }
  }
}))(Button);

const useStyles = makeStyles(theme => ({
  yesToggled: {
    color: theme.palette.common.black,
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '& + button': {
      borderLeftColor: 'transparent'
    }
  },
  noToggled: {
    color: theme.palette.common.black,
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  }
}));

export default function YesNoButton(props) {
  const { value, handleInputChange } = props;

  const classes = useStyles(props);

  return (
    <ButtonGroup
      disableRipple={true}
      disableFocusRipple={true}
    >
      <StyledButton
        className={value !== null ? (value ? classes.yesToggled : null) : null}
        onClick={() => handleInputChange({ target: { name: 'isRejectedSecond', value: true } })}
      >
        Yes
      </StyledButton>
      <StyledButton
        className={value !== null ? (value ? null : classes.noToggled) : null}
        onClick={() => handleInputChange({ target: { name: 'isRejectedSecond', value: false } })}
      >
        No
      </StyledButton>
    </ButtonGroup>
  )
}
