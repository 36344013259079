import React, { Fragment } from 'react';

import Async from 'react-async';

import ErrorMessage from '../error-message';
import Calculator from './Calculator';
import Cost from './components/Cost';
import Time from './components/Time';
import Fixing from './components/Fixing';
import Banner from './components/Banner';

const product1ImgSrc = require('../..//assets/images/banner-product-1.png');
const product2ImgSrc = require('../../assets/images/banner-product-2.png');

export default function Results({ calcValues, handleResetValues }) {
  const loadResults = async () => {
    const calculator = new Calculator({
      roles: calcValues.roles.filter(role => role.checked),
      quantityCreated: calcValues.quantityCreated,
      pctRejectedFirst: calcValues.pctRejectedFirst,
      isRejectedSecond: calcValues.isRejectedSecond,
      pctRejectedSecond: calcValues.pctRejectedSecond
    });

    const data = await calculator.results();

    return data
  }

  return (
    <Async promiseFn={loadResults}>
      {({ data, error, isPending }) => {
        if (isPending) return null
        if (error) return <ErrorMessage status={500} handleResetValues={handleResetValues} />
        if (data)
          return (
            <Fragment>
              <Cost
                costPerYear={data.costPerYear}
                costPerMonth={data.costPerMonth}
                costPerWeek={data.costPerWeek}
                costPerRAMS={data.costPerRAMS}
                roles={data.roles}
              />
              <Banner imgSrc={product1ImgSrc} />
              <Time
                timePerYear={data.timePerYear}
                timePerMonth={data.timePerMonth}
                timePerWeek={data.timePerWeek}
                timePerRAMS={data.timePerRAMS}
                roles={data.roles}
              />
              <Fixing
                costPerYear={data.costPerYear}
                costCreatingPerYear={data.costCreatingPerYear}
                costFixingPerYear={data.costFixingPerYear}
                timeFixingPerYear={data.timeFixingPerYear}
                costFixingPerRams={data.costFixingPerRams}
              />
              <Banner imgSrc={product2ImgSrc} withReviewsBadge={true} />
            </Fragment>
          )
        return null
      }}
    </Async>
  )
}
