import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import PopupBox from '../popup-box';

import { WarningButton } from '../buttons';
import { SecondaryLink } from '../links';

const useStyles = makeStyles(theme => ({
  modalContent: {
    maxWidth: 480,
    textAlign: 'center',
    color: theme.palette.common.black,
    padding: theme.spacing(5, 5, 0, 5),
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300
    }
  },
  question: {
    margin: '12px 0'
  },
  info: {
    margin: '0 0 40px 0'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
}));

export default function WarningPopupBox(props) {
  const { open, question, info, warningButtonText, handleCloseModal, handleClick } = props;

  const classes = useStyles();

  return (
    <PopupBox open={open} handleClose={handleCloseModal}>
      <div className={classes.modalContent}>
      <Typography className={classes.question} variant='h3'>{question}</Typography>
      <Typography className={classes.info} variant='body1' component='p'>{info}</Typography>
      <div className={classes.actions}>
        <WarningButton disableRipple={true} disableFocusRipple={true} onClick={handleClick}>{warningButtonText}</WarningButton>
        <SecondaryLink component='button' href='#0' onClick={handleCloseModal}>Close</SecondaryLink>
      </div>
      </div>
    </PopupBox>
  )
}
