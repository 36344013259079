import { hoursAsMinutes } from './number';

const thousandsSeperated = (value, options = {}) => {
  const currency = options.currency || false;

  let parts = String(value).split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (currency && parts[1]) { parts[1] = parts[1].length === 1 ? `${parts[1]}0` : parts[1]; }

  return parts.join('.')
};

const displayHrs = hours => `${thousandsSeperated(hours)} hrs`;

const displayHoursMinutes = hours => {
  const hrs = Math.floor(hours);
  const mins = hoursAsMinutes(hours) - (hrs * 60);

  const displayHours = `${hrs ? `${hrs} ${hrs === 1 ? 'hour' : 'hours'}` : ''}`;
  const displayMinutes = `${mins ? `${mins} ${mins === 1 ? 'minute' : 'minutes'}` : ''}`;

  return (
    (displayHours && displayMinutes && `${displayHours} ${displayMinutes}`) ||
    (displayHours && `${displayHours}`) ||
    `${displayMinutes}`
  )
};

const displayCurrency = money => `£${thousandsSeperated(money, { currency: true })}`;

const displayPercentage = pct => `${pct}%`;

export {
  thousandsSeperated,
  displayHrs,
  displayHoursMinutes,
  displayCurrency,
  displayPercentage
}
