import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, FormControl, InputBase } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    borderRadius: 4,
    boxShadow: 'none',
    display: 'flex',
    width: '100%',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[500]
    }
  },
  fieldset: {
    width: '100%',
  },
  input: {
    fontWeight: theme.typography.h5.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: theme.typography.h5.lineHeight,
    color: theme.palette.common.black,
    '& input': {
      width: '100%',
      height: 40,
      padding: '15px 24px 17px 24px'
    }
  }
}));

export default function TextField(props) {
  const { inputBaseProps } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <FormControl className={classes.fieldset} component='fieldset'>
        <InputBase
          {...inputBaseProps}
          className={classes.input}
        />
      </FormControl>
    </Paper>
  )
}
