import { useEffect }  from 'react';

import { useLocation } from 'react-router-dom';

export default function AnalyticsListener({ children }) {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && location) {
      const _hsq = window._hsq = window._hsq || [];

      _hsq.push(['setPath', location.pathname])
      _hsq.push(['trackPageView'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return children
}
