export default class Calculator {
  constructor(properties) {
    this.roles = properties.roles;
    this.quantityCreated = properties.quantityCreated;
    this.pctRejectedFirst = properties.pctRejectedFirst;
    this.isRejectedSecond = properties.isRejectedSecond;
    this.pctRejectedSecond = properties.pctRejectedSecond;
  }

  quantityRejectedFirst() { return this.pctRejectedFirst && ((this.pctRejectedFirst / 100) * this.quantityCreated) }
  quantityRejectedSecond() { return this.isRejectedSecond && this.pctRejectedSecond && ((this.pctRejectedSecond / 100) * this.quantityCreated) }
  quantityRejected() { return this.quantityRejectedFirst() + this.quantityRejectedSecond() }

  rolesTimeValid(key) {
    switch (key) {
      case 'timeCreating': return true
      case 'timeFixingFirst': return this.pctRejectedFirst
      case 'timeFixingSecond': return this.isRejectedSecond && this.pctRejectedSecond
      default: return false
    }
  }
  roleCost(role, key) { return this.rolesTimeValid(key) && (role[key] * (role.salary === 'defined' ? role.definedSalary : role.averageSalary) / 2080) }
  rolesCost(key) { return this.roles.reduce((cost, role) => cost + this.roleCost(role, key), 0) }

  costCreatingPerMonth() { return this.rolesCost('timeCreating') * this.quantityCreated }
  costFixingPerMonth() {
    return (
      this.rolesCost('timeFixingFirst') * this.quantityRejectedFirst() +
      this.rolesCost('timeFixingSecond') * this.quantityRejectedSecond()
    )
  }
  costFixingPerRAMS() { return this.costFixingPerMonth() / this.quantityRejected() || 0 }
  costPerMonth() { return this.costCreatingPerMonth() + this.costFixingPerMonth() }
  costPerRAMS() { return this.rolesCost('timeCreating') + this.rolesCost('timeFixingFirst') + this.rolesCost('timeFixingSecond') }

  roleTime(role, key) { return this.rolesTimeValid(key) && role[key] }
  rolesTime(key) { return this.roles.reduce((time, role) => time + role[key], 0) }

  timeCreatingPerMonth() { return this.rolesTime('timeCreating') * this.quantityCreated }
  timeFixingPerMonth() {
    return (
      this.rolesTime('timeFixingFirst') * this.quantityRejectedFirst() +
      this.rolesTime('timeFixingSecond') * this.quantityRejectedSecond()
    )
  }
  timePerMonth() { return this.timeCreatingPerMonth() + this.timeFixingPerMonth() }
  timePerRAMS() { return this.rolesTime('timeCreating') + this.rolesTime('timeFixingFirst') + this.rolesTime('timeFixingSecond') }

  setRoles() {
    return this.roles.map(role => {
      const costPerMonth = (
        this.roleCost(role, 'timeCreating') * this.quantityCreated +
        this.roleCost(role, 'timeFixingFirst') * this.quantityRejectedFirst() +
        this.roleCost(role, 'timeFixingSecond') * this.quantityRejectedSecond()
      );

      const timePerMonth = (
        this.roleTime(role, 'timeCreating') * this.quantityCreated +
        this.roleTime(role, 'timeFixingFirst') * this.quantityRejectedFirst() +
        this.roleTime(role, 'timeFixingSecond') * this.quantityRejectedSecond()
      );

      return {
        name: role.name,
        costPerYear: costPerMonth * 12,
        timePerYear: timePerMonth * 12
      }
    })
  }

  results() {
    return new Promise((resolve, reject) => {
      const results = {
        costPerYear: this.costPerMonth() * 12,
        costPerMonth: this.costPerMonth(),
        costPerWeek: this.costPerMonth() / 4.3,
        costPerRAMS: this.costPerRAMS(),
        timePerYear: this.timePerMonth() * 12,
        timePerMonth: this.timePerMonth(),
        timePerWeek: this.timePerMonth() / 4.3,
        timePerRAMS: this.timePerRAMS(),
        costCreatingPerYear: this.costCreatingPerMonth() * 12,
        costFixingPerYear: this.costFixingPerMonth() * 12,
        costFixingPerRams: this.costFixingPerRAMS(),
        timeFixingPerYear: this.timeFixingPerMonth() * 12,
        roles: this.setRoles()
      };

      resolve(results)
    })
  }
}
