import { withStyles } from '@material-ui/core/styles';
import WarningButton from './WarningButton';

const WarningButtonSmall = withStyles(theme => ({
  root: {
    padding: '7px 16px 9px 16px'
  }
}))(WarningButton);

export default WarningButtonSmall
