import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 3,
    maxWidth: 320,
    maxHeight: 68
  }
}));

export default function ReviewsBadge(props) {
  const classes = useStyles();

  const id = `reviews-${uuidv4()}`;

  useEffect(() => {
    if (window.reviewsBadgeRibbon) {
      window.reviewsBadgeRibbon(id, { store: 'hands-hq', size: 'small', starsClr: '#65DCA4' })
    }
  }, [id])

  return (<div id={id} className={classes.wrapper}></div>)
};
