import { useState } from 'react';

export default function useForm(defaultValues) {
  const [values, setValues] = useState(defaultValues);

  const eventTargetValues = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    return {name, value}
  };

  const handleInputChange = (event) => {
    const { name, value } = eventTargetValues(event);

    setValues({...values, [name]: value})
  };

  const handleOptionChange = (event, meta = {}) => {
    const { name, value } = eventTargetValues(event);
    const array = [...values[meta.name]];

    array[meta.value] = { ...array[meta.value], [name]: value }
    setValues({...values, [meta.name]: array})
  };

  const handleOptionToggle = (event, meta = {}) => {
    const { name, value } = eventTargetValues(event);
    const array = [...values[name]];

    switch (meta.action) {
      case 'ADD':
        array.push(value)
        break;
      case 'REMOVE':
        array.splice(value, 1)
        break;
      default:
        console.log(`${meta.action} is not a valid meta action`)
    }

    setValues({...values, [name]: array})
  };

  return [values, setValues, handleInputChange, handleOptionChange, handleOptionToggle]
}
