import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

import { DigitField } from '../form-elements';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    width: '100%',
    padding: 0,
    marginBottom: 60,
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  question: {
    marginBottom: theme.spacing(3)
  }
}));

export default function RoleTime(props) {
  const { roleIndex, roleKey, role, handleOptionChange } = props;

  const classes = useStyles();

  const handleRoleChange = event => {
    handleOptionChange(event, { name: 'roles', value: roleIndex })
  };

  return (
    <Paper className={classes.root}>
      <Typography className={classes.question} variant='h4' component='div'>{role.name}</Typography>
      <DigitField
        decimalScale={2}
        labelText='hours per RAMS'
        inputBaseProps={{ name: roleKey, value: role[roleKey], onChange: handleRoleChange }}
      />
    </Paper>
  )
}
