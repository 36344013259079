import React, { Fragment } from 'react';
import { Typography, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import RoleToggler from '../role-toggler';

const useStyles = makeStyles(theme => ({
  question: {
    marginBottom: 60
  },
  fieldset: {
    width: '100%',
    paddingBottom: 80
  }
}));

export default function PeopleContent(props) {
  const { formValues, handleOptionChange, handleOptionToggle } = props;
  const { roles } = formValues;

  const classes = useStyles();

  const isMaxCheckedRoles = roles.filter(role => role.checked).length === 4;

  return (
    <Fragment>
      <Typography className={classes.question} variant='h2'>Who creates RAMS at your company?</Typography>
      <FormControl className={classes.fieldset} component='fieldset'>
        {roles.map((role, index) =>
          <RoleToggler key={index} roleIndex={index} role={role} isMaxCheckedRoles={isMaxCheckedRoles} handleOptionChange={handleOptionChange} handleOptionToggle={handleOptionToggle} />
        )}
      </FormControl>
    </Fragment>
  )
}

