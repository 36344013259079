import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { PrimaryButton } from '../buttons';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    overflowWrap: 'break-word',
    borderRadius: 3,
    padding: theme.spacing(3)
  },
  text: {
    margin: '0 0 20px 0'
  }
}));

export default function CopyText(props) {
  const { text, buttonText } = props;

  const [copied, setCopied] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.text} variant='body2' component='p'>{text}</Typography>
      <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
        <PrimaryButton disableRipple={true} disableFocusRipple={true}>{copied ? 'Copied' : buttonText}</PrimaryButton>
      </CopyToClipboard>
    </div>
  )
}
