import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  active: {
    color: theme.palette.common.black,
    width: 24,
    height: 24,
    lineHeight: '20px',
    borderRadius: '50%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
    textAlign: 'center'
  },
  circle: {
    color: theme.palette.common.white,
    width: 24,
    height: 24,
    padding: 2,
    borderRadius: '50%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    fontSize: 18,
    textAlign: 'center'
  },
  inactive: {
    color: theme.palette.common.white,
    width: 24,
    height: 24,
    lineHeight: '20px',
    borderRadius: '50%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    textAlign: 'center'
  }
}));

export default function StepIconComponent(props) {
  const { icon, active, completed } = props;

  const classes = useStyles();

  if (active) {
    return (<div className={classes.active}>{icon}</div>)
  } else if (completed) {
    return (
      <div className={classes.circle}>
        <CheckIcon fontSize='inherit' />
      </div>
    )
  } else {
    return (<div className={classes.inactive}>{icon}</div>)
  }
}
