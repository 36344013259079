import { createMuiTheme } from '@material-ui/core/styles';

const appTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 690,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      black: '#0A182E',
      white: '#FFFFFF'
    },
    background: {
      default: '#FFFFFF'
    },
    primary: {
      main: '#65DCA4',
      hover: '#5ACD97',
      active: '#4EB786',
      disabled: '#96E7C1'
    },
    secondary: {
      main: '#3391F3',
      hover: '#0D78E7',
      active: '#0466CC'
    },
    warning: {
      main: '#D9534F',
      hover: '#C9302C',
      active: '#AC2A25'
    },
    grey: {
      100: '#F6F9FC',
      200: '#E4E5EB',
      300: '#CDCED9',
      500: '#AEB5C0',
      700: '#5E708F'
    }
  },
  typography: {
    fontFamily: '"Mark-Pro", Helvetica, Arial, sans-serif',
    letterSpacing: '0rem',
    h1: {
      fontWeight: 700,
      fontSize: '5.5rem',
      lineHeight: '6rem',
      color: '#0A182E'
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: '3rem',
      color: '#0A182E'
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
      color: '#0A182E'
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      color: '#0A182E'
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
      color: '#0A182E'
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      color: '#0A182E'
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      color: '#0A182E'
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      color: '#0A182E'
    },
    body1: {
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: '1.5rem'
    },
    body2: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.25rem',
      color: '#0A182E'
    }
  }
});

export default appTheme
