import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, FormControl, InputBase, Typography } from '@material-ui/core';

import { DigitFormat } from '../formats';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    borderRadius: 4,
    boxShadow: 'none',
    display: 'inline-flex',
    padding: 0,
    '&:hover': {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[500]
    }
  },
  fieldset: {
    width: 156,
    '& input': {
      textAlign: 'center'
    }
  },
  input: {
    fontWeight: theme.typography.h5.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: theme.typography.h5.lineHeight,
    color: theme.palette.common.black,
    '& input': {
      width: '100%',
      height: 40,
      padding: '15px 24px 17px 24px'
    }
  },
  verticalLine: {
    borderRightWidth: 2,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.grey[200]
  },
  labelWrapper: {
    padding: theme.spacing(3)
  }
}));

export default function DigitField(props) {
  const { inputBaseProps, labelText, decimalScale } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <FormControl className={classes.fieldset} component='fieldset'>
        <InputBase
          {...inputBaseProps}
          className={classes.input}
          inputComponent={DigitFormat}
          inputProps={{ pattern: '[0-9]*', decimalScale: decimalScale || 0 }}
        />
      </FormControl>
      {labelText && (
        <Fragment>
          <div className={classes.verticalLine}></div>
          <div className={classes.labelWrapper}><Typography variant='subtitle1' component='span'>{labelText}</Typography></div>
        </Fragment>
      )}
    </Paper>
  )
}
