import moment from 'moment';

function round(number, decimals = 0) {
  return Number(Math.round(number + 'e' + decimals) + 'e-' + decimals)
}

function hoursAsMinutes(hours) {
  return round(moment.duration(round(hours, 2), 'hours').asMinutes())
}

function minutesAsHours(minutes) {
  return round(moment.duration(round(minutes), 'minutes').asHours(), 2)
}

export {
  round,
  hoursAsMinutes,
  minutesAsHours
}
