import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, FormControl } from '@material-ui/core';

import RoleTime from '../role-time';
import PercentageSlider from '../percentage-slider';
import { YesNoButton } from '../buttons';

const useStyles = makeStyles(theme => ({
  question: {
    marginBottom: 60
  },
  fieldset: {
    paddingBottom: 120,
    '&:last-of-type': {
      paddingBottom: 80
    }
  },
  fieldsetFullWidth: {
    width: '100%',
    paddingBottom: 120
  }
}));

export default function RamsRejectionContent(props) {
  const { formValues, handleInputChange, handleOptionChange } = props;
  const { roles, pctRejectedFirst, isRejectedSecond, pctRejectedSecond } = formValues;

  const classes = useStyles();

  return (
    <Fragment>
      <Typography className={classes.question} variant='h2'>What percentage of RAMS are rejected by&nbsp;clients?</Typography>
      <FormControl className={classes.fieldsetFullWidth} component='fieldset'>
        <PercentageSlider
          name='pctRejectedFirst'
          value={pctRejectedFirst}
          marks={[{ value: '72', label: 'Industry average 72%' }]}
          handleInputChange={handleInputChange}
        />
      </FormControl>
      <Typography className={classes.question} variant='h2'>How long does it take to fix a RAMS after a&nbsp;rejection?</Typography>
      <FormControl className={classes.fieldset} component='fieldset'>
        {roles.map((role, index) =>
          role.checked && (
            <RoleTime key={index} roleIndex={index} roleKey='timeFixingFirst' role={role} handleOptionChange={handleOptionChange} />
          )
        )}
      </FormControl>
      <Typography className={classes.question} variant='h2'>Are your RAMS ever rejected a&nbsp;second&nbsp;time?</Typography>
      <FormControl className={classes.fieldset} component='fieldset'>
        <YesNoButton
          value={isRejectedSecond}
          handleInputChange={handleInputChange}
        />
      </FormControl>
      {isRejectedSecond && (
        <Fragment>
          <Typography className={classes.question} variant='h2'>What percentage of RAMS are rejected a&nbsp;second&nbsp;time?</Typography>
          <FormControl className={classes.fieldsetFullWidth} component='fieldset'>
            <PercentageSlider
              name='pctRejectedSecond'
              value={pctRejectedSecond}
              marks={[{ value: '32', label: 'Industry average 32%' }]}
              handleInputChange={handleInputChange}
            />
          </FormControl>
          <Typography className={classes.question} variant='h2'>How long does each role spend fixing a RAMS when it is rejected a&nbsp;second&nbsp;time?</Typography>
          <FormControl className={classes.fieldset} component='fieldset'>
            {roles.map((role, index) =>
              role.checked && (
                <RoleTime key={index} roleIndex={index} roleKey='timeFixingSecond' role={role} handleOptionChange={handleOptionChange} />
              )
            )}
          </FormControl>
        </Fragment>
      )}
    </Fragment>
  )
}
