import { withStyles } from '@material-ui/core/styles';
import PrimaryButton from './PrimaryButton';

const PrimaryButtonSmall = withStyles(theme => ({
  root: {
    padding: '7px 16px 9px 16px',
    '&:disabled': {
      color: theme.palette.common.white,
      background: theme.palette.primary.disabled,
      opacity: 0.7
    }
  }
}))(PrimaryButton);

export default PrimaryButtonSmall
