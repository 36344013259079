import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import usePopupBox from '../hooks/usePopupBox';

import Icon from '../../assets/images/results-share.png';

import { PrimaryButton } from '../buttons';
import { SecondaryLink } from '../links';
import PopupBox from '../popup-box';
import CopyText from '../copy-text';

const useStyles = makeStyles(theme => ({
  modalContent: {
    maxWidth: 480,
    textAlign: 'center',
    color: theme.palette.common.black,
    padding: theme.spacing(5, 5, 0, 5),
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300
    }
  },
  copyTitle: {
    margin: '12px 0'
  },
  copyInstructions: {
    margin: '0 0 40px 0'
  }
}));

export default function Share() {
  const [isModalOpen, handleOpenModal, handleCloseModal] = usePopupBox();

  const classes = useStyles();

  return (
    <Fragment>
      <PrimaryButton disableRipple={true} disableFocusRipple={true} onClick={handleOpenModal}>
        Share your results
      </PrimaryButton>
      <PopupBox open={isModalOpen} handleClose={handleCloseModal}>
        <div className={classes.modalContent}>
          <img src={Icon} alt='results-share' height={60} />
          <Typography className={classes.copyTitle} variant='h3'>Share your results</Typography>
          <Typography className={classes.copyInstructions} variant='body1' component='p'>Copy and paste your unique link below into emails, chats or browsers</Typography>
          <CopyText text={window.location.href} buttonText='Copy link' />
          <SecondaryLink component='button' href='#0' onClick={handleCloseModal}>Close</SecondaryLink>
        </div>
      </PopupBox>
    </Fragment>
  )
}
