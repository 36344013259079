import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Card, CardContent, CardActions } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 120,
    marginBottom: 120,
    [theme.breakpoints.down('sm')]: {
      marginTop: 60,
      marginBottom: 60
    }
  },
  card: {
    boxShadow: 'none',
    padding: 0
  },
  cardContent: {
    padding: 0,
    '&:last-child': { paddingBottom: 0 }
  },
  cardActions: {
    padding: 0,
    float: 'left'
  }
}));

export default function FormStep(props) {
  const { content, actions } = props;

  const classes = useStyles();

  return (
    <Container maxWidth='md' className={classes.root}>
      <Card className={classes.card} square={true}>
        <CardContent className={classes.cardContent}>
          {content}
        </CardContent>
        <CardActions className={classes.cardActions}>
          {actions}
        </CardActions>
      </Card>
    </Container>
  )
}
