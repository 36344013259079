import { withStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';

const CustomSlider = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
    margin: 0
  },
  thumb: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 3,
    boxShadow: 'none',
    width: 32,
    height: 32,
    marginTop: -13,
    marginLeft: -16,
    '& .bar': {
      height: 12,
      width: 2,
      backgroundColor: theme.palette.common.white,
      marginLeft: 2,
      marginRight: 2,
    },
    '&:hover': {
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    }
  },
  mark: {
    width: 0,
    height: 0,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
    borderBottomWidth: 10,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    marginTop: 32,
    marginLeft: -7,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 10,
      left: -1,
      borderRightWidth: 2,
      borderRightStyle: 'solid',
      borderRightColor: theme.palette.common.black,
      height: 18
    }
  },
  markLabel: {
    display: 'inline-block',
    fontWeight: 400,
    fontSize: theme.typography.subtitle1.fontSize,
    lineHeight: theme.typography.subtitle1.lineHeight,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 4,
    pointerEvents: 'none',
    marginTop: 56,
    padding: '6px 12px'
  },
  valueLabel: {
    left: 'calc(-50%)',
  },
  track: {
    color: theme.palette.primary.main,
    height: 8,
    borderRadius: 0
  },
  rail: {
    color: theme.palette.grey[200],
    height: 8,
    borderRadius: 0
  },
}))(Slider);

export default CustomSlider
