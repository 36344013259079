import React  from 'react';

import { DarkTooltip } from '../tooltips';
import { PrimaryButtonLarge } from '../buttons';

export default function IndustryActions(props) {
  const { formValues, handleNext } = props;
  const { industry, otherIndustry, turnover } = formValues;

  const validIndustry = industry === 'Other' ? otherIndustry : industry;

  const isNextEnabled = !!(validIndustry && turnover);

  return (
    <DarkTooltip
      arrow
      disableFocusListener={isNextEnabled}
      disableHoverListener={isNextEnabled}
      disableTouchListener={isNextEnabled}
      title='Please complete all fields to continue'
      placement='top'
    >
      <span>
        <PrimaryButtonLarge disableRipple={true} disableFocusRipple={true} disabled={!isNextEnabled} onClick={handleNext}>Next</PrimaryButtonLarge>
      </span>
    </DarkTooltip>
  )
}
