import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, FormControl } from '@material-ui/core';

import { DigitField } from '../form-elements';
import RoleTime from '../role-time';

const useStyles = makeStyles(theme => ({
  question: {
    marginBottom: 60
  },
  fieldset: {
    paddingBottom: 120,
    '&:last-of-type': {
      paddingBottom: 80
    }
  }
}));

export default function RamsCreationContent(props) {
  const { formValues, handleInputChange, handleOptionChange } = props;
  const { roles, quantityCreated } = formValues;

  const classes = useStyles();

  return (
    <Fragment>
      <Typography className={classes.question} variant='h2'>How many RAMS does your company create in a&nbsp;month?</Typography>
      <FormControl className={classes.fieldset} component='fieldset'>
        <DigitField
          labelText='RAMS per month'
          inputBaseProps={{ name: 'quantityCreated', value: quantityCreated, onChange: handleInputChange }}
        />
      </FormControl>
      <Typography className={classes.question} variant='h2'>How long does each role spend creating a&nbsp;RAMS?</Typography>
      <FormControl className={classes.fieldset} component='fieldset'>
        {roles.map((role, index) =>
          role.checked && (
            <RoleTime key={index} roleIndex={index} roleKey='timeCreating' role={role} handleOptionChange={handleOptionChange} />
          )
        )}
      </FormControl>
    </Fragment>
  )
}
