import React from 'react';
import NumberFormat from 'react-number-format';

export default function PercentFormat(props) {
  const { inputRef, name, onChange, ...other } = props;

  const handleIsAllowed = (values) => {
    const leadingZero = values.value.length > 1 && values.value.startsWith('0');
    const inRange = values.value === '' || values.floatValue <= 100;

    return !leadingZero && inRange
  };

  return (
    <NumberFormat
      {...other}
      decimalScale={0}
      allowNegative={false}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      isAllowed={handleIsAllowed}
      onValueChange={values => onChange({ target: { name: name, value: values.value ? Number(values.value) : null } })}
    />
  )
}
