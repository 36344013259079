const defaultRoleValues = {
  custom: true,
  iconFilename: 'ic-role-custom.png',
  name: '',
  salary: 'defined',
  averageSalary: null,
  definedSalary: null,
  quantityInvolved: null,
  checked: false,
  timeCreating: null,
  timeFixingFirst: null,
  timeFixingSecond: null
};

export default defaultRoleValues
