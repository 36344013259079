import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const WarningButton = withStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    background: theme.palette.warning.main,
    border: 0,
    borderRadius: 3,
    fontWeight: 700,
    fontSize: '1rem',
    fontFamily: 'inherit',
    lineHeight: '1.25rem',
    textTransform: 'none',
    padding: '11px 30px 13px 30px',
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.warning.hover
    },
    '&:active': {
      color: theme.palette.common.white,
      background: theme.palette.warning.active
    },
    '&:disabled': {
      color: theme.palette.common.white,
      background: theme.palette.grey[300]
    }
  }
}))(Button);

export default WarningButton
