import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, FormControlLabel, FormControl, InputBase, Radio } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    borderRadius: 4,
    boxShadow: 'none',
    display: 'flex',
    width: '100%',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[500]
    }
  },
  formControlLabel: {
    margin: 0
  },
  radio: {
    padding: theme.spacing(1),
    margin: theme.spacing(2, 1, 2, 2),
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  label: {
    width: '100%',
    marginRight: theme.spacing(3)
  },
  verticalLine: {
    borderRightWidth: 2,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.grey[200]
  },
  fieldset: {
    width: '100%'
  },
  input: {
    fontWeight: theme.typography.h5.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: theme.typography.h5.lineHeight,
    color: theme.palette.common.black,
    '& input': {
      width: '100%',
      height: 40,
      padding: '15px 24px 17px 24px'
    }
  }
}));

export default function OptionTextField(props) {
  const { labelText, formControlLabelProps, radioProps, inputBaseProps } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <FormControlLabel
        {...formControlLabelProps}
        className={classes.formControlLabel}
        control={<Radio {...radioProps} className={classes.radio} color='primary' />}
        label={<Typography variant='subtitle1' component='span' className={classes.label}>{labelText}</Typography>}
      />
      <div className={classes.verticalLine}></div>
      <FormControl className={classes.fieldset} component='fieldset'>
        <InputBase
          {...inputBaseProps}
          className={classes.input}
        />
      </FormControl>
    </Paper>
  )
}
