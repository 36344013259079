import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: 200
  },
  total: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    height: 200
  },
  text: {
    marginBottom: 4
  },
  element: {
    marginTop: theme.spacing(3)
  },
  elementKey: {
    display: 'inline-block',
    borderRadius: '100%',
    width: 8,
    height: 8,
    marginTop: 6,
    marginRight: 12,
    verticalAlign: 'top'
  },
  elementInfo: {
    display: 'inline-block',
    width: 180,
  }
}));

export default function Chart(props) {
  const { total, colors, display, data, dataKey } = props;

  const classes = useStyles();

  const ordered = data.sort((a, b) => (a[dataKey] < b[dataKey]) ? 1 : -1)

  return (
    <div className={classes.root}>
      <div className={classes.total}>
        <Typography className={classes.text} variant='body2' component='div'>Total</Typography>
        <Typography variant='subtitle1' component='div'>{display(total)}</Typography>
      </div>
      <PieChart width={200} height={200}>
        <Pie
          cx={95}
          cy={95}
          data={ordered}
          dataKey={dataKey}
          innerRadius={60}
          outerRadius={90}
        >
          {ordered.map((element, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} strokeWidth={0} />)}
        </Pie>
      </PieChart>
      {ordered.map((element, index) => (
        <div key={`element-${index}`} className={classes.element}>
          <div className={classes.elementKey} style={{ backgroundColor: colors[index % colors.length]}}></div>
          <div className={classes.elementInfo}>
            <Typography className={classes.text} variant='body2' component='div'>{element.name}</Typography>
            <Typography variant='subtitle1' component='div'>{display(element[dataKey])}</Typography>
          </div>
        </div>
      ))}
    </div>
  )
}
