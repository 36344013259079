import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { PrimaryButton } from '../buttons';

import Icon from '../../assets/images/ic-error.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  startAgain: {
    marginTop: theme.spacing(4)
  },
  icon: {
    display: 'inline-block',
    marginBottom: 12
  },
  message: {
    textAlign: 'center'
  }
}));

export default function ErrorMessage(props) {
  const { status, handleResetValues } = props;

  const history = useHistory();

  const classes = useStyles();

  const handleStartAgain = () => {
    handleResetValues()
    history.push('/')
  }

  const renderMessage = () => {
    switch (status) {
      case 404:
        return (
          <Fragment>
            <Typography variant='h4' component='p'>Unfortunately that page couldn't be found.</Typography>
            <Typography variant='h4' component='p'>Please check the URL and try again.</Typography>
          </Fragment>
        )
      case 500:
        return (
          <Fragment>
            <Typography variant='h4' component='p'>Unfortunately something went wrong.</Typography>
            <Typography variant='h4' component='p'>Please start again to calculate your results.</Typography>
            <PrimaryButton className={classes.startAgain} disableRipple={true} disableFocusRipple={true} onClick={handleStartAgain}>Start again</PrimaryButton>
          </Fragment>
        )
      default: return false
    }
  }

  return (
    <div className={classes.root}>
      <img className={classes.icon} src={Icon} alt='error' width={60} height={60} />
      <div className={classes.message}>{renderMessage()}</div>
    </div>
  )
}
