import React, { createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, RadioGroup } from '@material-ui/core';

import { displayCurrency } from '../../utils/display';
import { CurrencyFormat } from '../../formats';
import { OptionField, OptionTextField, DigitField, MoneyField, TextField } from '../../form-elements';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7)
  },
  questionContainer: {
    paddingBottom: 60
  },
  questionText: {
    marginBottom: theme.spacing(3)
  },
  lightText: {
    fontWeight: 400
  }
}));

export default function RoleTogglerForm(props) {
  const { roleIndex, role, handleOptionChange } = props;

  const classes = useStyles();

  const definedSalaryRef = createRef();

  const focusInput = event => definedSalaryRef.current.focus();

  const handleRoleChange = event => handleOptionChange(event, { name: 'roles', value: roleIndex });

  const handleDefinedSalaryFocus = (event) => {
    role.salary !== 'defined' && handleRoleChange({ target: { name: 'salary', value: 'defined' } }, { name: 'roles', value: roleIndex })
  }

  return (
    <div className={classes.root}>
      {role.custom && (
        <div className={classes.questionContainer}>
          <Typography className={classes.questionText} variant='h4'>What is the name of the role?</Typography>
          <TextField
            inputBaseProps={{ name: 'name', value: role.name, onChange: handleRoleChange }}
          />
        </div>
      )}
      <div className={classes.questionContainer}>
        <Typography className={classes.questionText} variant='h4'>What is the average annual salary for this role?</Typography>
        <RadioGroup aria-label='salary' name='salary' value={role.salary} onChange={handleRoleChange}>
          {role.averageSalary && (
            <OptionField
              labelText={<span>{displayCurrency(role.averageSalary)} <span className={classes.lightText}>(industry average)</span></span>}
              formControlLabelProps={{ value: 'average' }}
            />
          )}
          {role.custom ? (
            <MoneyField
              inputBaseProps={{ name: 'definedSalary', value: role.definedSalary, onChange: handleRoleChange }}
            />
          ) : (
            <RadioGroup row aria-label='salary' name='salary' value={role.salary} onChange={handleRoleChange}>
              <OptionTextField
                labelText='£'
                formControlLabelProps={{ value: 'defined' }}
                radioProps={{ onChange: focusInput }}
                inputBaseProps={{
                  inputRef: definedSalaryRef,
                  name: 'definedSalary',
                  value: role.definedSalary,
                  inputComponent: CurrencyFormat,
                  inputProps: { pattern: '[0-9]*' },
                  onChange: handleRoleChange,
                  onFocus: handleDefinedSalaryFocus
                }}
              />
            </RadioGroup>
          )}
        </RadioGroup>
      </div>
      <div>
        {role.custom ? (
          <Typography className={classes.questionText} variant='h4'>How many people with this role are involved in creating RAMS?</Typography>
        ) : (
          <Typography className={classes.questionText} variant='h4'>How many {role.name.toLowerCase()}s are involved in creating RAMS?</Typography>
        )}
        <DigitField
          inputBaseProps={{ name: 'quantityInvolved', value: role.quantityInvolved, onChange: handleRoleChange }}
        />
      </div>
    </div>
  )
}
