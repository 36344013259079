import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const PrimaryButton = withStyles(theme => ({
  root: {
    color: theme.palette.common.black,
    background: theme.palette.primary.main,
    border: 0,
    borderRadius: 3,
    fontWeight: 700,
    fontSize: '1rem',
    fontFamily: 'inherit',
    lineHeight: '1.25rem',
    textTransform: 'none',
    padding: '11px 30px 13px 30px',
    '&:hover': {
      color: theme.palette.common.black,
      background: theme.palette.primary.hover
    },
    '&:active': {
      color: theme.palette.common.black,
      background: theme.palette.primary.active
    },
    '&:disabled': {
      color: theme.palette.common.white,
      background: theme.palette.primary.disabled,
      opacity: 0.7
    }
  }
}))(Button);

export default PrimaryButton
