import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import Async from 'react-async';

import useForm from '../hooks/useForm';
import useProgress from '../hooks/useProgress';

import { defaultFormValues } from '../defaults';
import { Show, Form } from '../calculation';
import { app } from '../themes';
import api from '../api';

import AnalyticsListener from '../analytics/AnalyticsListener';
import ErrorMessage from '../error-message';
import Header from '../header';
import Footer from '../footer';

export default function App() {
  const [
    formValues,
    setFormValues,
    handleInputChange,
    handleOptionChange,
    handleOptionToggle
  ] = useForm(defaultFormValues);

  const [numberOfSteps, activeStep, setStartProgress, setEndProgress, handleNext] = useProgress(4, 0);

  const handleResetValues = () => {
    setStartProgress()
    setFormValues(defaultFormValues)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  return (
    <Router>
      <AnalyticsListener>
        <ThemeProvider theme={app}>
          <CssBaseline />
          <div className='header'>
            <Header
              numberOfSteps={numberOfSteps}
              activeStep={activeStep}
              handleResetValues={handleResetValues}
            />
          </div>
          <div className='content'>
            <Async promiseFn={api.csrf.getOne}>
              {({ error, isPending }) => {
                if (error) return <ErrorMessage status={500} handleResetValues={handleResetValues} />
                if (!isPending && !error)
                  return (
                    <Switch>
                      <Route path='/calculations/:calculationUUID'>
                        <Show setEndProgress={setEndProgress} handleResetValues={handleResetValues} />
                      </Route>
                      <Route path='/'>
                        <Form
                          numberOfSteps={numberOfSteps}
                          activeStep={activeStep}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleNext={handleNext}
                          handleResetValues={handleResetValues}
                          handleInputChange={handleInputChange}
                          handleOptionChange={handleOptionChange}
                          handleOptionToggle={handleOptionToggle}
                        />
                      </Route>
                    </Switch>
                  )
                return null
              }}
            </Async>
          </div>
          <div className='footer'>
            <Footer />
          </div>
        </ThemeProvider>
      </AnalyticsListener>
    </Router>
  )
}
