import React  from 'react';

import { DarkTooltip } from '../tooltips';
import { PrimaryButtonLarge } from '../buttons';

export default function PeopleActions(props) {
  const { formValues, handleNext } = props;
  const { roles } = formValues;

  const checkedRoles = roles.filter(role => role.checked);

  const validRoles = checkedRoles.every(role => role.name && role.quantityInvolved && (role.salary === 'average' || role.definedSalary));

  const isNextEnabled = checkedRoles.length > 0 && validRoles;

  return (
    <DarkTooltip
      arrow
      disableFocusListener={isNextEnabled}
      disableHoverListener={isNextEnabled}
      disableTouchListener={isNextEnabled}
      title='Please complete all fields to continue'
      placement='top'
    >
      <span>
        <PrimaryButtonLarge disableRipple={true} disableFocusRipple={true} disabled={!isNextEnabled} onClick={handleNext}>Next</PrimaryButtonLarge>
      </span>
    </DarkTooltip>
  )
}
