import React  from 'react';
import { DarkTooltip } from '../tooltips';
import { PrimaryButtonLarge } from '../buttons';

export default function RamsRejectionActions(props) {
  const { formValues, handleNext } = props;
  const { roles, pctRejectedFirst, isRejectedSecond, pctRejectedSecond } = formValues;

  const checkedRoles = roles.filter(role => role.checked);

  const blankFirstPercentage = !pctRejectedFirst;
  const blankSecondPercentage = pctRejectedFirst && !pctRejectedSecond;

  const validFirstTimeRejection = (
    blankFirstPercentage || (pctRejectedFirst && checkedRoles.every(role => role.timeFixingFirst !== null))
  );

  const validSecondTimeRejection = (
    blankFirstPercentage || blankSecondPercentage || (pctRejectedSecond && checkedRoles.every(role => role.timeFixingSecond !== null))
  );

  const isNextEnabled = validFirstTimeRejection && isRejectedSecond !== null && (isRejectedSecond ? validSecondTimeRejection : true);

  return (
    <DarkTooltip
      arrow
      disableFocusListener={isNextEnabled}
      disableHoverListener={isNextEnabled}
      disableTouchListener={isNextEnabled}
      title='Please complete all fields to continue'
      placement='top'
    >
      <span>
        <PrimaryButtonLarge disableRipple={true} disableFocusRipple={true} disabled={!isNextEnabled} onClick={handleNext}>See results</PrimaryButtonLarge>
      </span>
    </DarkTooltip>
  )
}
