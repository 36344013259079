import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';

import usePopupBox from '../hooks/usePopupBox';

import { LightTooltip } from '../tooltips';
import { WarningPopupBox } from '../popup-box';
import ProgressConnector from './components/ProgressConnector';
import StepIconComponent from './components/StepIconComponent';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '14px'
  },
  stepper: {
    height: 24,
    backgroundColor: theme.palette.common.black,
    padding: 0,
  },
  step: {
    flex: '0 0 auto',
    height: 24,
    paddingLeft: 4,
    paddingRight: 4
  },
  circle: {
    color: theme.palette.common.white,
    width: 24,
    height: 24,
    padding: 2,
    fontSize: 18,
    borderRadius: '50%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    textAlign: 'center',
    '&:hover': {
      color: theme.palette.common.black,
      background: theme.palette.common.white,
    }
  },
  reset: {
    marginLeft: 12,
    cursor: 'pointer'
  }
}));

export default function Progress(props) {
  const { numberOfSteps, activeStep, handleResetValues } = props;

  const [isModalOpen, handleOpenModal, handleCloseModal] = usePopupBox();

  const history = useHistory();

  const classes = useStyles();

  const handleStartAgain = () => {
    handleResetValues()
    history.push('/')
    handleCloseModal()
  }

  return (
    <div className={classes.root}>
      <Stepper className={classes.stepper} alternativeLabel connector={<ProgressConnector />} activeStep={activeStep}>
        {[...Array(numberOfSteps).keys()].map(key => (
          <Step className={classes.step} key={key}>
            <StepLabel StepIconComponent={StepIconComponent}></StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.reset} onClick={handleOpenModal}>
        <LightTooltip arrow title='Start again' placement='bottom'>
          <div className={classes.circle}>
            <ReplayIcon fontSize='inherit' />
          </div>
        </LightTooltip>
      </div>
      <WarningPopupBox
        open={isModalOpen}
        question='Are you sure you want to start again?'
        info='The progress you have made will be lost'
        warningButtonText='Yes, start again'
        handleCloseModal={handleCloseModal}
        handleClick={handleStartAgain}
      />
    </div>
  )
}
