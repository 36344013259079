import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1rem',
    maxWidth: 200,
    borderRadius: 3,
    padding: '11px 16px 13px 16px'
  },
  arrow: {
    color: theme.palette.grey[100]
  }
}))(Tooltip);

export default LightTooltip
