const axios = require('axios')

export default {
  csrf: {
    getOne: async () => {
      const result = await axios.get('/api/csrf');
      return result.data
    }
  },
  calculations: {
    getOne: async ({ uuid }) => {
      const result = await axios.get(`/api/calculations/${uuid}`);
      return result.data
    },
    create: async ({ params }) => {
      const result = await axios.post('/api/calculations', params);
      return result.data
    }
  }
}
