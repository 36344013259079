import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { WarningButtonSmall, PrimaryButtonSmall } from '../../buttons';
import { DarkTooltip } from '../../tooltips';

const useStyles = makeStyles(theme => ({
  root: {
    height: 60,
    display: 'flex',
    alignItems: 'center'
  }
}));

export default function RoleTogglerButton(props) {
  const { roleIndex, role, isMaxCheckedRoles, handleOptionChange, handleOptionToggle } = props;

  const classes = useStyles();

  const handleRoleToggle = event => {
    if (role.custom && role.checked) {
      handleOptionToggle({ target: { name: 'roles', value: roleIndex } }, { action: 'REMOVE' })
    } else {
      handleOptionChange({ target: { name: 'checked', value: !role.checked } }, { name: 'roles', value: roleIndex })
    }
  };

  return (
    <div className={classes.root}>
      {role.checked ? (
        <WarningButtonSmall disableRipple={true} disableFocusRipple={true} onClick={handleRoleToggle}>Remove</WarningButtonSmall>
      ) : (
        <DarkTooltip
          arrow
          disableFocusListener={!isMaxCheckedRoles}
          disableHoverListener={!isMaxCheckedRoles}
          disableTouchListener={!isMaxCheckedRoles}
          title='You can only add up to 4 roles'
          placement='top'
        >
          <span>
            <PrimaryButtonSmall disabled={isMaxCheckedRoles} disableRipple={true} disableFocusRipple={true} onClick={handleRoleToggle}>Add</PrimaryButtonSmall>
          </span>
        </DarkTooltip>
      )}
    </div>
  )
}
