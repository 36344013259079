import defaultRoleValues from './defaultRoleValues';

const defaultFormValues = {
  industry: '',
  otherIndustry: '',
  turnover: '',
  roles: [
    { ...defaultRoleValues, custom: false, iconFilename: 'ic-role-pm.png', name: 'Project/contracts manager', salary: 'average', averageSalary: 42000, checked: false },
    { ...defaultRoleValues, custom: false, iconFilename: 'ic-role-hs.png', name: 'Health & safety manager', salary: 'average', averageSalary: 40000, checked: false },
    { ...defaultRoleValues, custom: false, iconFilename: 'ic-role-sup.png', name: 'Supervisor', salary: 'average', averageSalary: 35000, checked: false },
    { ...defaultRoleValues }
  ],
  quantityCreated: null,
  pctRejectedFirst: 72,
  isRejectedSecond: null,
  pctRejectedSecond: 32
};

export default defaultFormValues
