import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Card, CardContent } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Async from 'react-async';

import api from '../api';
import Share from './Share';
import ErrorMessage from '../error-message';
import Results from '../results';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 272,
    backgroundColor: theme.palette.common.black
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: '4rem',
    lineHeight: '4.5rem',
    marginBottom: theme.spacing(4)
  },
  card: {
    boxShadow: 'none',
    padding: 0
  },
  cardContent: {
    padding: 0,
    '&:last-child': { paddingBottom: 0 }
  }
}));

export default function Show({ setEndProgress, handleResetValues }) {
  const classes = useStyles();

  const { calculationUUID } = useParams();

  useEffect(() => {
    setEndProgress && setEndProgress()
  }, [setEndProgress])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.analytics && window.analytics.page('Calculator-Results')
    }
  }, [])

  return (
    <Async promiseFn={api.calculations.getOne} uuid={calculationUUID}>
      {({ data, error, isPending }) => {
        if (isPending) return null
        if (error) return <ErrorMessage status={404} handleResetValues={handleResetValues}/>
        if (data && !error)
          return (
            <Fragment>
              <div className={classes.header}>
                <Typography className={classes.title} component='div'>Your results</Typography>
                <Share />
              </div>
              <Container maxWidth='md'>
              <Card className={classes.card} square={true}>
                <CardContent className={classes.cardContent}>
                  <Results calcValues={data} handleResetValues={handleResetValues} />
                </CardContent>
              </Card>
              </Container>
            </Fragment>
          )
        return null
      }}
    </Async>
  )
}
