import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

import usePopupBox from '../hooks/usePopupBox';

import Logo from '../../assets/images/handshq-grey@2x.png';

import { TertiaryLink } from '../links';
import { WarningPopupBox } from '../popup-box';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100]
  },
  outerContainer: {
    display: 'flex'
  },
  gridContainer: {
    minHeight: 80,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gridItemTop: {
    '& img': {
      display: 'block',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  },
  gridItemBottom: {
    display: 'flex',
    color: theme.palette.grey[700],
  },
  gridItemBottomFirst: {
    paddingRight: theme.spacing(4),
    paddingBottom: 1,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingBottom: theme.spacing(2)
    }
  },
  gridItemBottomSecond: {
    paddingRight: theme.spacing(4),
    paddingBottom: 1,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingBottom: theme.spacing(2)
    }
  },
  gridItemBottomThird: {
    paddingBottom: 1,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2)
    }
  },
  text: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem'
  }
}));

export default function Footer(props) {
  const classes = useStyles();

  const [isModalOpen, handleOpenModal, handleCloseModal] = usePopupBox();

  const handleVisitWebsite = () => {
    window.location.href = 'https://www.handshq.com/'
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Container className={classes.outerContainer} maxWidth='lg'>
          <Grid
            container
            className={classes.gridContainer}
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid item className={classes.gridItemTop}>
              <img src={Logo} alt='hhq-logo-grey' width={176} height={27} onClick={handleOpenModal} />
            </Grid>
            <Grid item className={classes.gridItemBottom}>
              <Grid
                container
                className={classes.gridContainer}
                direction='row'
                justify='space-between'
                alignItems='center'
              >
                <Grid item className={classes.gridItemBottomFirst}>
                  <TertiaryLink href='https://www.handshq.com'>Go to main website</TertiaryLink>
                </Grid>
                <Grid item className={classes.gridItemBottomSecond}>
                  <TertiaryLink href='https://www.handshq.com/privacy'>Privacy policy</TertiaryLink>
                </Grid>
                <Grid item className={classes.gridItemBottomThird}>
                  <Typography className={classes.text}>&#169; 2020, HANDSHQ Limited</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <WarningPopupBox
        open={isModalOpen}
        question='Are you sure you want to go to the main website?'
        info='The progress you have made will be lost'
        warningButtonText='Yes, go to main website'
        handleCloseModal={handleCloseModal}
        handleClick={handleVisitWebsite}
      />
    </Fragment>
  )
}
