import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, FormControlLabel, Radio } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    borderRadius: 4,
    boxShadow: 'none',
    display: 'flex',
    width: '100%',
    marginBottom: 12,
    '&:last-of-type': {
      marginBottom: 0
    },
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[500]
    }
  },
  formControlLabel: {
    width: '100%',
    margin: 0
  },
  radio: {
    padding: theme.spacing(1),
    margin: theme.spacing(2, 1, 2, 2),
    '&:hover': {
      backgroundColor: "transparent"
    }
  },
  label: {
    width: '100%'
  }
}));

export default function OptionField(props) {
  const { labelText, formControlLabelProps, radioProps } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <FormControlLabel
        {...formControlLabelProps}
        className={classes.formControlLabel}
        control={<Radio className={classes.radio} {...radioProps} color='primary' />}
        label={<Typography variant='subtitle1' component='span' className={classes.label}>{labelText}</Typography>}
      />
    </Paper>
  )
}
