import { useState } from 'react';

export default function useProgress(numberOfSteps, value) {
  const [activeStep, setActiveStep] = useState(value);

  const startProgress = () => setActiveStep(value);
  const endProgress = () => setActiveStep(numberOfSteps);
  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1);

  return [numberOfSteps, activeStep, startProgress, endProgress, handleNext]
}
