import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

import Icon from '../../../assets/images/results-fixing.png';

import { round } from '../../utils/number';
import { thousandsSeperated, displayHoursMinutes, displayCurrency } from '../../utils/display';
import Breakdown from './Breakdown';
import Chart from './Chart';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingBottom: 120,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  icon: {
    marginBottom: 16
  },
  heading: {
    marginBottom: 12
  },
  total: {
    marginBottom: 60
  },
  symbol: {
    fontWeight: 700,
    fontSize: '3.5rem',
    lineHeight: '6rem',
    color: theme.palette.common.black,
    verticalAlign: 'bottom',
    marginRight: theme.spacing(1)
  },
  infoWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
  chartWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 60
    },
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[100],
    minWidth: 260,
    maxWidth: 400,
    padding: theme.spacing(5, 0)
  }
}));

export default function Cost(props) {
  const {
    costPerYear,
    costCreatingPerYear,
    costFixingPerYear,
    timeFixingPerYear,
    costFixingPerRams
  } = props;

  const classes = useStyles();

  const chartData = [
    { name: 'Creating RAMS', costPerYear: round(costCreatingPerYear) },
    { name: 'Fixing RAMS', costPerYear: round(costFixingPerYear) }
  ];

  return (
    <Container maxWidth='lg'>
      <Grid
        container
        className={classes.gridContainer}
        direction='row'
        justify='space-between'
      >
        <Grid item className={classes.infoWrapper}>
          <img className={classes.icon} src={Icon} alt='results-cost' height={48} />
          <Typography className={classes.heading} variant='subtitle1' component='h3'>Each year, fixing RAMS is costing your business</Typography>
          <div className={classes.total}>
            <Typography className={classes.symbol} component='span'>£</Typography>
            <Typography variant='h1' component='span'>{thousandsSeperated(round(costFixingPerYear))}</Typography>
          </div>
          <Breakdown value={round(timeFixingPerYear)} display={displayHoursMinutes} period='per year' />
          <Breakdown value={round(costFixingPerRams, 2)} display={displayCurrency} period='per RAMS' />
        </Grid>
        <Grid item className={classes.chartWrapper}>
          <Chart
            total={round(costPerYear)}
            colors={['#20BBA5', '#DD4938']}
            display={displayCurrency}
            data={chartData}
            dataKey='costPerYear'
          />
        </Grid>
      </Grid>
    </Container>
  )
}
