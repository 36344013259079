import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(5)
  },
  container: {
    backgroundColor: theme.palette.common.white,
    borderWidth: 1,
    borderRadius: 3,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    outline: 'none'
  },
  backdrop: {
    backgroundColor: 'rgba(10, 24, 46, 0.8)'
  }
}));

export default function PopupBox(props) {
  const { children, open, handleClose } = props;

  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500, classes: { root: classes.backdrop }}}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className={classes.container}>
          {children}
        </div>
      </Fade>
    </Modal>
  )
}
