import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

import usePopupBox from '../hooks/usePopupBox';

import Logo from '../../assets/images/handshq-white@2x.png';

import Progress from '../progress';
import { WarningPopupBox } from '../popup-box';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.black
  },
  outerContainer: {
    display: 'flex'
  },
  gridContainer: {
    minHeight: 80,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  logo: {
    textAlign: 'left',
    minWidth: 176,
    '& img': {
      display: 'block',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      textAlign: 'center'
    }
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  },
  titleText: {
    color: theme.palette.common.white,
    fontWeight: 700,
    paddingBottom: 1
  },
  progress: {
    textAlign: 'right',
    minWidth: 176,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
      textAlign: 'center'
    }
  }
}));

export default function Header(props) {
  const { activeStep, numberOfSteps, handleResetValues } = props;

  const classes = useStyles();

  const [isModalOpen, handleOpenModal, handleCloseModal] = usePopupBox();

  const handleVisitWebsite = () => {
    window.location.href = 'https://www.handshq.com/'
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Container className={classes.outerContainer} maxWidth='lg'>
          <Grid
            container
            className={classes.gridContainer}
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid item className={classes.logo}>
              <img src={Logo} alt='hhq-logo-white' widht={176} height={27} onClick={handleOpenModal}/>
            </Grid>
            <Grid item className={classes.title}>
              <Typography className={classes.titleText} variant='subtitle1' component='h1'>The hidden cost of RAMS calculator</Typography>
            </Grid>
            <Grid item className={classes.progress}>
              <Progress numberOfSteps={numberOfSteps} activeStep={activeStep} handleResetValues={handleResetValues} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <WarningPopupBox
        open={isModalOpen}
        question='Are you sure you want to go to the main website?'
        info='The progress you have made will be lost'
        warningButtonText='Yes, go to main website'
        handleCloseModal={handleCloseModal}
        handleClick={handleVisitWebsite}
      />
    </Fragment>
  )
}
