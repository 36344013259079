import React from 'react';
import NumberFormat from 'react-number-format';

export default function CurrencyFormat(props) {
  const { inputRef, name, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      decimalScale={0}
      allowNegative={false}
      thousandSeparator=','
      getInputRef={inputRef}
      onValueChange={values => onChange({ target: { name: name, value: values.value ? Number(values.value) : null } })}
    />
  )
}
