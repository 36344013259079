import { withStyles } from '@material-ui/core/styles';
import { StepConnector } from '@material-ui/core';

const ProgressConnector = withStyles(theme => ({
  root: {
    marginLeft: -8,
    marginRight: -8,
    width: 8
  },
  line: {
    borderColor: theme.palette.common.white
  },
}))(StepConnector);

export default ProgressConnector
