import { withStyles } from '@material-ui/core/styles';
import PrimaryButton from './PrimaryButton';

const PrimaryButtonLarge = withStyles(theme => ({
  root: {
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    padding: '15px 40px 17px 40px'
  }
}))(PrimaryButton);

export default PrimaryButtonLarge
