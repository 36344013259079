import { withStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

const SecondaryLink = withStyles(theme => ({
  root: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: '1rem',
    fontFamily: 'inherit',
    lineHeight: '1.25rem',
    margin: theme.spacing(3),
    '&:before': {
      content: '""',
      position: 'absolute',
      height: 2,
      width: '100%',
      top: '22px',
      left: '0px',
      backgroundColor: theme.palette.primary.main,
      pointerEvents: 'none',
      zIndex: 9
    },
    '&:hover': {
      color: theme.palette.primary.hover,
      textDecoration: 'none',
      '&:before': {
        backgroundColor: theme.palette.primary.hover
      }
    },
    '&:active': {
      color: theme.palette.primary.active,
      textDecoration: 'none',
      '&:before': {
        backgroundColor: theme.palette.primary.active
      }
    },
    '&:disabled': {
      color: theme.palette.grey[300],
      textDecoration: 'none',
      '&:before': {
        backgroundColor: theme.palette.grey[300]
      }
    }
  }
}))(Link);

export default SecondaryLink
