import { withStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

const TertiaryLink = withStyles(theme => ({
  root: {
    color: theme.palette.grey[700],
    fontWeight: 400,
    fontSize: '0.875rem',
    fontFamily: 'inherit',
    lineHeight: '1.25rem',
    padding: 0,
    '&:hover': {
      color: theme.palette.grey[700],
    },
    '&:active': {
      color: theme.palette.grey[700],
    },
    '&:disabled': {
      color: theme.palette.grey[300],
      textDecoration: 'none'
    }
  }
}))(Link);

export default TertiaryLink
