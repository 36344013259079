import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import { PercentageField } from '../form-elements';
import CustomSlider from './components/CustomSlider';
import CustomThumbComponent from './components/CustomThumbComponent';

const useStyles = makeStyles(theme => ({
  root: {
    height: 140
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none'
  },
  sliderGutter: {
    height: 8,
    minWidth: 16,
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer'
  },
  fieldWrapper: {
    flex: '0 0 auto'
  }
}));

export default function PercentageSlider(props) {
  const { name, value, marks, handleInputChange } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div
          className={classes.sliderGutter}
          onClick={() => {
            handleInputChange({ target: { name: name, value: 0 } })
          }}
        >
        </div>
        <CustomSlider
          value={value}
          marks={marks}
          defaultValue={72}
          ThumbComponent={CustomThumbComponent}
          onChange={(event, newValue) => {
            handleInputChange({ target: { name: name, value: newValue } })
          }}
        />
        <div className={classes.fieldWrapper}>
          <PercentageField
            className={classes.field}
            inputBaseProps={{
              name: name,
              value: value,
              onChange: handleInputChange
            }}
          />
        </div>
      </Paper>
    </div>
  )
}
