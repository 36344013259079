import React, { useEffect } from 'react';

export default function FormSteps(props) {
  const { children, numberOfSteps, activeStep, onCompleted } = props;

  useEffect(() => {
    activeStep === numberOfSteps && onCompleted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, numberOfSteps])

  return <main>{Array.isArray(children) ? children[activeStep] : children}</main>
}
