import React, { Fragment, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, FormControl, RadioGroup } from '@material-ui/core';

import { OptionField, OptionTextField } from '../form-elements';

const useStyles = makeStyles(theme => ({
  question: {
    marginBottom: 60
  },
  fieldset: {
    width: '100%',
    paddingBottom: 120,
    '&:last-of-type': {
      paddingBottom: 80
    }
  },
  otherIndustry: {
    marginTop: 12
  }
}));

export default function IndustryContent(props) {
  const { formValues, handleInputChange } = props;
  const { industry, otherIndustry, turnover } = formValues;

  const classes = useStyles();

  const otherRef = useCallback(node => {
    node !== null && industry === 'Other' && node.focus()
  }, [industry]);

  const industryRadioButtonLabels = [
    'Civil engineering',
    'Construction',
    'Events/entertainment',
    'Facilities management',
    'Information and communications technology',
    'Mechanical & electrical',
    'Specialist contractor',
    'Transportation',
    'Utilities',
    'Waste management'
  ];

  const turnoverRadioButtonLabels = [
    'Less than £1 million',
    '£1-10 million',
    '£10-36 million',
    'More than £36 million',
    'Don\'t know'
  ];

  const handleOtherFocus = (event) => {
    industry !== 'Other' && handleInputChange({ target: { name: 'industry', value: 'Other' } })
  }

  return (
    <Fragment>
      <Typography className={classes.question} variant='h2'>What industry is your company in?</Typography>
      <FormControl className={classes.fieldset} component='fieldset'>
        <RadioGroup name='industry' value={industry} onChange={handleInputChange}>
          {industryRadioButtonLabels.map(value =>
            <OptionField key={value} labelText={value} formControlLabelProps={{ value: value }} />
          )}
        </RadioGroup>
        <RadioGroup className={classes.otherIndustry} name='industry' value={industry} onChange={handleInputChange}>
          <OptionTextField
            labelText='Other:'
            formControlLabelProps={{ value: 'Other' }}
            inputBaseProps={{
              inputRef: otherRef,
              name: 'otherIndustry',
              value: otherIndustry,
              onChange: handleInputChange,
              onFocus: handleOtherFocus
            }}
          />
        </RadioGroup>
      </FormControl>
      <Typography className={classes.question} variant='h2'>What is your annual turnover?</Typography>
      <FormControl className={classes.fieldset} component='fieldset'>
        <RadioGroup name='turnover' value={turnover} onChange={handleInputChange}>
          {turnoverRadioButtonLabels.map(value =>
            <OptionField key={value} labelText={value} formControlLabelProps={{ value: value }} />
          )}
        </RadioGroup>
      </FormControl>
    </Fragment>
  )
}
