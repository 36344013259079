import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core';

import { defaultRoleValues } from '../defaults';
import RoleTogglerForm from './components/RoleTogglerForm';
import RoleTogglerButton from './components/RoleTogglerButton';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    borderRadius: 4,
    boxShadow: 'none',
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: 0
  },
  gridContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  img: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-8px'
    }
  },
  roleNameWrapper: {
    height: 60,
    display: 'flex',
    alignItems: 'center'
  },
  buttonWrapper: {
    textAlign: 'right'
  }
}));

export default function RoleToggler(props) {
  const { roleIndex, role, isMaxCheckedRoles, handleOptionChange, handleOptionToggle } = props;

  const classes = useStyles();

  const iconSrc = require(`../../assets/images/${role.iconFilename}`);

  useEffect(() => {
    role.custom && role.checked && handleOptionToggle({ target: { name: 'roles', value: { ...defaultRoleValues } } }, { action: 'ADD' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role.custom, role.checked])

  return (
    <Paper className={classes.root}>
      <Grid
        container
        className={classes.gridContainer}
        spacing={3}
      >
        <Grid item>
          <img className={classes.img} src={iconSrc} alt='role icon' height={60} width={60} />
        </Grid>
        <Grid item xs>
          <div className={classes.roleNameWrapper}>
            <Typography variant='h6' component='h3'>{role.custom ? 'Add a custom role' : role.name}</Typography>
          </div>
          {role.checked && (
            <RoleTogglerForm
              roleIndex={roleIndex}
              role={role}
              handleOptionChange={handleOptionChange}
            />
          )}
        </Grid>
        <Grid item className={classes.buttonWrapper}>
          <RoleTogglerButton
            roleIndex={roleIndex}
            role={role}
            isMaxCheckedRoles={isMaxCheckedRoles}
            handleOptionChange={handleOptionChange}
            handleOptionToggle={handleOptionToggle}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
