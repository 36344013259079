import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import { ReviewsBadge } from '../../reviews';

import { PrimaryButton } from '../../buttons';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    position: 'relative',
    marginBottom: 120,
    borderRadius: 8
  },
  shadow: {
    position: 'absolute',
    top: 16,
    right: 16,
    bottom: 16,
    left: 16,
    '-webkit-box-shadow': '0 6px 40px 0 rgba(0, 0, 0, 0.5)',
    '-moz-box-shadow': '0 6px 40px 0 rgba(0, 0, 0, 0.5)',
    boxShadow: '0 6px 40px 0 rgba(0, 0, 0, 0.5)',
    zIndex: 1
  },
  leftContainer: props => ({
    minHeight: props.height,
    borderRadius: '8px 0 0 8px',
    padding: '54px 40px',
    backgroundColor: theme.palette.common.black,
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      borderRadius: 8
    }
  }),
  rightContainer: props => ({
    minHeight: props.height,
    paddingTop: props.rightContainerPaddingTop,
    borderRadius: '0 8px 8px 0',
    backgroundColor: theme.palette.common.black,
    zIndex: 2
  }),
  imageContainer: props => ({
    width: '100%',
    height: '100%',
    borderBottomRightRadius: 7,
    backgroundImage: props.imageUrl,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '512px'
  }),
  bannerHeading: {
    color: theme.palette.common.white,
    fontSize: 30,
    lineHeight: '36px',
    margin: 0
  },
  bannerText: {
    color: theme.palette.common.white,
    fontSize: 20,
    lineHeight: '24px',
    margin: '20px 0 42px 0'
  },
  reviewsBadgeWrapper: {
    margin: '42px 0 0 0'
  }
}));

export default function Banner({ imgSrc, withReviewsBadge }) {
  const styleProps = {
    imageUrl: `url(${imgSrc})`,
    rightContainerPaddingTop: (withReviewsBadge ? 60 : 40)
  };

  const classes = useStyles(styleProps);

  const handleClick = () => {
    window.location.href='https://try.handshq.com/enterprise'
  }

  return (
    <Container maxWidth='lg'>
      <Grid
        container
        className={classes.gridContainer}
        direction='row'
        justify='space-between'
      >
        <Grid item xs={12} className={classes.shadow}></Grid>
        <Grid item xs={12} sm={6} className={classes.leftContainer}>
          <h3 className={classes.bannerHeading}>
            Create better RAMS in minutes, not hours
          </h3>
          <p className={classes.bannerText}>
            Create site-specific, pictorial
            RAMS using HANDS HQ, the UK's
            #1 risk assessment software
          </p>
          <PrimaryButton disableRipple={true} disableFocusRipple={true} onClick={handleClick}>
            Get a free trial
          </PrimaryButton>
          {withReviewsBadge && <div className={classes.reviewsBadgeWrapper}><ReviewsBadge /></div>}
        </Grid>
        <Grid item xs={false} sm={6} className={classes.rightContainer}>
          <div className={classes.imageContainer}></div>
        </Grid>
      </Grid>
    </Container>
  )
}
