import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAsync } from 'react-async';

import api from '../api';
import ErrorMessage from '../error-message';
import FormSteps from '../form-steps';
import FormStep from '../form-step';
import { IndustryContent, IndustryActions } from '../industry';
import { PeopleContent, PeopleActions } from '../people';
import { RamsCreationContent, RamsCreationActions } from '../rams-creation';
import { RamsRejectionContent, RamsRejectionActions } from '../rams-rejection';

export default function Form(props) {
  const {
    numberOfSteps,
    activeStep,
    formValues,
    handleNext,
    handleResetValues,
    handleInputChange,
    handleOptionChange,
    handleOptionToggle
  } = props;

  const history = useHistory();

  const calculation = useAsync({
    deferFn: () => {
      return api.calculations.create({
        params: {
          industry: formValues.industry,
          otherIndustry: formValues.otherIndustry,
          turnover: formValues.turnover,
          roles: formValues.roles,
          quantityCreated: formValues.quantityCreated,
          pctRejectedFirst: formValues.pctRejectedFirst,
          isRejectedSecond: formValues.isRejectedSecond,
          pctRejectedSecond: formValues.pctRejectedSecond
        }
      })
    },
    onResolve: data => history.push(`/calculations/${data.uuid}`)
  })

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.analytics && window.analytics.page('Calculator-Start')
    }
  }, [])

  if (calculation.error) {
    return <ErrorMessage status={500} handleResetValues={handleResetValues} />
  } else {
    return (
      <FormSteps
        numberOfSteps={numberOfSteps}
        activeStep={activeStep}
        onCompleted={calculation.run}
      >
        <FormStep
          content={<IndustryContent formValues={formValues} handleInputChange={handleInputChange} />}
          actions={<IndustryActions formValues={formValues} handleNext={handleNext} />}
        />
        <FormStep
          content={<PeopleContent formValues={formValues} handleOptionChange={handleOptionChange} handleOptionToggle={handleOptionToggle} />}
          actions={<PeopleActions formValues={formValues} handleNext={handleNext} />}
        />
        <FormStep
          content={<RamsCreationContent formValues={formValues} handleInputChange={handleInputChange} handleOptionChange={handleOptionChange} />}
          actions={<RamsCreationActions formValues={formValues} handleNext={handleNext} />}
        />
        <FormStep
          content={<RamsRejectionContent formValues={formValues} handleInputChange={handleInputChange} handleOptionChange={handleOptionChange} />}
          actions={<RamsRejectionActions formValues={formValues} handleNext={handleNext} />}
        />
      </FormSteps>
    )
  }
}
